import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React from 'react'

import type {
  CaseStudiesListQuery,
  CaseStudiesListQueryVariables,
} from '../../../graphql-types'
import PaginationLink, {
  PaginationOuter,
} from '../../components/PaginationLink'
import Page from '../../components/layout/Page'
import Container from '../../components/modules/Container'
import Hero from '../../components/modules/Hero'
import Spacer from '../../components/modules/Spacer'
import Footer from '../../components/resource/Footer'
import Preview from '../../components/resource/Preview'
import Grid from '../../components/resource/PreviewsGrid'

export type Query = CaseStudiesListQuery
export type QueryVariables = CaseStudiesListQueryVariables
export type Context = QueryVariables & {
  nextPagePath?: string | null
  prevPagePath?: string | null
}

export const query = graphql`
  query CaseStudiesList(
    $where: ContentfulCaseStudyFilterInput
    $limit: Int
    $skip: Int
  ) {
    list: allContentfulCaseStudy(
      limit: $limit
      skip: $skip
      filter: $where
      sort: { fields: createdAt, order: DESC }
    ) {
      items: nodes {
        ...PreviewCaseStudy
      }
    }
  }
`

type Props = PageProps<Query, Context>

const CaseStudiesList = ({ data, pageContext }: Props) => {
  const pages = data.list.items
  const { nextPagePath, prevPagePath } = pageContext

  return (
    <Page
      head={{
        title: 'Clients Using Remote Assistants',
        description:
          'Learn more about companies getting ahead by partnering with an experienced Double assistant.',
      }}
    >
      <Hero
        title={'Executives achieve more with Double'}
        text={{
          text: 'Learn more about companies getting ahead by partnering with an experienced Double assistant.',
        }}
      />
      <Spacer size={'Medium'} />

      <Container>
        <Grid itemsCount={pages.length}>
          {pages.map((page) => (
            <Preview key={page.slug} page={page} rootPath={'/customers'} />
          ))}
        </Grid>
      </Container>

      {!!(prevPagePath || nextPagePath) && (
        <>
          <PaginationOuter>
            {!!prevPagePath && (
              <PaginationLink to={prevPagePath}>
                {'← Previous customers'}
              </PaginationLink>
            )}
            {!!nextPagePath && (
              <PaginationLink to={nextPagePath}>
                {'Next customers →'}
              </PaginationLink>
            )}
          </PaginationOuter>
          <Spacer size={'Large'} />
        </>
      )}

      <Footer />
    </Page>
  )
}

export default CaseStudiesList
